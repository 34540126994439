import React from 'react';

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        {/*<span className="d-block">Address Point</span>*/}
        Hans-Pollak-Weg 10/9
        <br />
        1220 Wien, Austria
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{' '}
        <a href="mailto:code@iteratus.com">code@iteratus.com</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
