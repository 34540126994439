import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ModalContent from '../components/blog/ModalContent';

import { entry as entry1 } from '../components/blog/content/blog-2022-03-26';
import { entry as entry2 } from '../components/blog/content/blog-2022-03-27';
import { entry as entry3 } from '../components/blog/content/blog-2022-06-30';
import { entry as entry4 } from '../components/blog/content/blog-2022-08-01';
import { entry as entry5 } from '../components/blog/content/blog-2023-08-10';
import { entry as entry6 } from '../components/blog/content/blog-2023-08-26';
import { entry as entry7 } from '../components/blog/content/blog-2023-08-29';
import { entry as entry8 } from '../components/blog/content/blog-2023-10-05';

import Modal from 'react-modal';
import { urlFriendly } from '../utils/utils';

Modal.setAppElement('#root');

const blogEntriesRaw = [
  entry8,
  entry7,
  entry6,
  entry5,
  entry4,
  entry3,
  entry2,
  entry1,
];

const blogEntries = blogEntriesRaw.map((entry) => {
  let slug = [entry.title, '-', entry.date.toISOString().split('T')[0]];

  entry.slug = urlFriendly(slug.join(''));
  return entry;
});

const Blog = () => {
  const navigate = useNavigate();
  const { blogId } = useParams();

  const [openData, setOpenData] = useState(null);

  useEffect(() => {
    const data = blogEntries.find((entry) => entry.slug === blogId) || null;
    setOpenData(data);
  }, [blogId, setOpenData]);

  const handleClose = () => {
    navigate('../');
  };

  return (
    <div className="blog">
      <div className="title-section text-left text-sm-center">
        <h1>
          my <span>blog</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      <div className="container">
        <div className="row pb-50">
          {blogEntries.map((entry) => (
            <div
              className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30"
              key={entry.date.getTime()}>
              <Link to={entry.slug} style={{ textDecoration: 'none' }}>
                <article className="post-container">
                  <div className="post-thumb">
                    <div className="d-block position-relative overflow-hidden">
                      <img
                        src={entry.image}
                        className="img-fluid"
                        alt={entry.title}
                      />
                    </div>
                  </div>
                  <div className="post-content">
                    <div className="entry-header">
                      <h3>{entry.title}</h3>
                    </div>
                    <div className="entry-content open-sans-font">
                      <p>{entry.excerpt}</p>
                    </div>
                  </div>
                </article>
              </Link>
            </div>
          ))}

          <Modal
            isOpen={openData !== null}
            onRequestClose={handleClose}
            contentLabel={`Read ${openData?.title}`}
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}>
            <button className="close-modal" onClick={handleClose}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>

            <div className="box_inner blog-post">
              <ModalContent {...(openData || {})} />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Blog;
