import Source from '../../Source';

export const entry = {
  image:
    'https://res.cloudinary.com/iteratus/image/upload/c_fit,f_auto,h_552,w_895/v1656591441/iteratus/sean-stratton-ObpCE_X3j6U-unsplash_ea4cjz.jpg',
  title: 'HTML order and stacking context',
  date: new Date('2022-06-30'),
  tags: 'Frontend development',
  excerpt:
    'Not only accessibility gains benefit through placing HTML in the right order. Knowledge about stacking ' +
    'context will save you some headache.',
  content: (
    <>
      <p>
        HTML elements are the first and most important contact point of our
        programming with the browser, the screen reader or the SEO bot. They
        transport the structural information of the data that we want to
        display.
      </p>
      <p>
        Since today we can change the order and also the appearance with CSS at
        any time, we tend to forget that the order of the HTML elements also has
        a great influence on the rendering of the page. The following applies
        here by default: The sibling that later appears in the DOM is always
        placed over the previous siblings.
      </p>
      <p>
        This knowledge already saves us a headache or two if we want to
        influence exactly this stacking with a <code>z-index</code>!
      </p>
      <h2 className="white">CSS stacking context</h2>
      <p>
        Apart from{' '}
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity"
          target="_blank"
          rel="noreferrer">
          CSS specificity
        </a>
        , there is another very interesting and complicated concept: the
        stacking context. The mentioned order of the HTML elements only refers
        to a specific context. Within this context, HTML elements with a{' '}
        <code>z-index</code> can be influenced in such a way that, for example,
        an underlying element is brought to the foreground. However, elements in
        different contexts cannot swap positions among themselves.
      </p>
      <p>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context"
          target="_blank"
          rel="noreferrer">
          See MDN for deeper explanation.
        </a>
      </p>
      <p>
        In more complex SPAs in particular, it can happen that you quickly find
        yourself in a tangle of contexts from which you can no longer find your
        way out so easily. So you should be really careful right from the start
        and build HTML structures and components with a bit of a foresight. And
        since the <code>opacity</code> and <code>transform</code> properties
        show up really quickly in CSS, it's a good idea to keep this topic in
        the back of your mind!
      </p>
      <p>
        One very important thing to mention here is the following rule:
        <br />
        Element with a <code>position</code> value <code>absolute</code> or{' '}
        <code>relative</code>
        <br />
        <strong>and</strong> <code>z-index</code> value other than{' '}
        <code>auto</code>.
      </p>
      <p>
        I repeat:
        <br />
        ... <strong>AND</strong> <code>z-index</code> value other than{' '}
        <code>auto</code>! So as long as you don't mess with the stacking order
        by using <code>z-index</code>, it's perfectly safe to position an
        element!
      </p>
      <p className="source">
        <Source handle="seanstratton" name="Sean Stratton" />
      </p>
    </>
  ),
};
