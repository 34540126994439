import React from 'react';
import SnackbarProvider from 'react-simple-snackbar';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollTopBehaviour from './components/ScrollTopBehaviour';
import Home from './views/Home';
import About from './views/About';
import Portfolio from './views/Portfolio';
import Blog from './views/Blog';
import Contact from './views/Contact';
import NotFound from './views/NotFound';
import Navigation from './components/Navigation';

const App = () => {
  return (
    <SnackbarProvider>
      <ScrollToTop />
      <BrowserRouter>
        <ScrollTopBehaviour />

        <Navigation />

        <div className="tab-panel_list">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio">
              <Route index element={<Portfolio />} />
              <Route path=":portfolioId" element={<Portfolio />} />
            </Route>
            <Route path="/blog">
              <Route index element={<Blog />} />
              <Route path=":blogId" element={<Blog />} />
            </Route>
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;
