import Source from '../../Source';

export const entry = {
  image:
    'https://res.cloudinary.com/iteratus/image/upload/c_fit,f_auto,h_552,w_895/v1659028225/iteratus/markus-winkler-afW1hht0NSs-unsplash_bzwlwk.jpg',
  title: 'Does documentation need to be super detailed?',
  date: new Date('2022-08-01'),
  tags: 'Documentation, food for thought',
  excerpt:
    'In times of Jira, stake holders and acceptance criteria we tend to be very literal with everything. But is this really helpful and healthy? And if so, to what degree?',
  content: (
    <>
      <p>
        Many user stories that we implement in our career have to be documented
        in great detail from many points of view. POs, QA, stakeholders, content
        managers, of course, all have their view of the world, so documentation
        is the common denominator that picks up all parties and finds a common
        language and also brings everyone's expectations into line. So, a really
        important and good thing!
      </p>
      <p>
        However, there is also documentation written by developers for
        developers. These differ drastically in the reach of the target group
        from the ones already mentioned. We don't necessarily have to pay
        attention to other professions here and can concentrate on the
        essentials, after all we are all developers. Junior, Intermediate,
        Senior, yes, but developers.
      </p>
      <p>
        The ability to hold multiple levels of complexity in mind, mentally
        connecting all parts of the code and even do this for code other
        developers produced during pull request reviews is really challenging!
        But hey, we love the tunnel, the focus, the flow into which our thinking
        submerges and sometimes doesn't resurface for hours. It's really like a
        red thread that you follow with satisfaction. It's the best days as a
        developer when you experience that. You feel productive, you have
        reached a goal that you would otherwise only get closer to days later.
      </p>
      <p>So all in all this makes developers very good thinking machines.</p>
      <p>
        I think we should therefore also be able to trust that developers are
        really good at dealing with rather generic documentation. Not everything
        has to be formulated down to the smallest detail, it is sufficient if
        the underlying concept is sketched and conveyed. However, if we write
        this detailed documentation instead, we are even more likely to produce
        errors. Finally, from the dev's point of view, I don't have to think
        about how to use the code anymore, everything has already been thought
        through and written down.
      </p>
      <p>
        ... and that's exactly the problem. With generic documentation, I cover
        far more cases that may arise in the future than I can describe in
        general. Yes, as a developer, I need to think more, need to understand
        more about how code works. All in all, however, I also deliver better
        and less error-prone work in the long term.
      </p>
      <p>
        So let's write less detailed documentation and focus more on education
        so that everybody can understand concept instead of following literal
        rules.
      </p>
      <p className="source">
        <Source handle="markuswinkler" name="Markus Winkler" />
      </p>
    </>
  ),
};
