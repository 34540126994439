import React from 'react';

const ModalContent = ({
  title,
  year,
  client,
  languages,
  website,
  websiteShort,
  image,
}) => {
  return (
    <div className="slideshow">
      <figure>
        <figcaption>
          <h3>{title}</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Client</span>:{' '}
              <span className="ft-wt-600 uppercase">{client}</span>
            </div>

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-calendar-o pr-2"></i>
              <span className="project-label">Year</span>:{' '}
              <span className="ft-wt-600 uppercase">{year}</span>
            </div>

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Tags</span>:{' '}
              <span className="ft-wt-600 uppercase">{languages}</span>
            </div>

            {website && (
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-external-link-square pr-2"></i>
                <span className="ft-wt-600 uppercase">
                  <a href={website} target="_blank" rel="noreferrer">
                    {websiteShort}
                  </a>
                </span>
              </div>
            )}
          </div>
        </figcaption>

        <img src={image} alt={title} />
      </figure>
    </div>
  );
};

export default ModalContent;
