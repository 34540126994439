import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const menuItem = [
  { icon: 'fa-home', menuName: 'Home', route: '/' },
  { icon: 'fa-user', menuName: 'About', route: '/about' },
  { icon: 'fa-briefcase', menuName: 'Portfolio', route: '/portfolio' },
  { icon: 'fa-comments', menuName: 'Blog', route: '/blog' },
  { icon: 'fa-envelope-open', menuName: 'Contact', route: '/contact' },
];

const Navigation = () => {
  const currentLocation = useLocation();

  return (
    <nav className="header">
      <ul className="icon-menu">
        {menuItem.map((item) => {
          let classNames = 'icon-box';

          if (currentLocation.pathname === item.route) {
            classNames += ' react-tabs__tab--selected';
          }

          return (
            <li className={classNames} key={item.menuName}>
              <Link className="naviLink" to={item.route}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
