import React from 'react';

const skillsContent = [
  { skillPercent: '98', skillName: 'HTML' },
  { skillPercent: '90', skillName: 'Accessibility' },
  { skillPercent: '85', skillName: 'CSS / SASS' },
  { skillPercent: '95', skillName: 'Javascript' },
  { skillPercent: '75', skillName: 'Typescript' },
  { skillPercent: '90', skillName: 'REACT' },
  { skillPercent: '70', skillName: 'NextJS' },
  { skillPercent: '40', skillName: 'GraphQL' },
  { skillPercent: '75', skillName: 'NodeJS' },
  { skillPercent: '60', skillName: 'PHP' },
  { skillPercent: '80', skillName: 'MySQL' },
  { skillPercent: '75', skillName: 'Wordpress' },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 p${val.skillPercent}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
