import Source from '../../Source';

export const entry = {
  image:
    'https://res.cloudinary.com/iteratus/image/upload/c_fit,f_auto,h_552,w_895/v1696491987/iteratus/joshua-rawson-harris-KRELIShKxTM-unsplash_dmz2ae.jpg',
  title: 'The overlooked appreciation of TypeScript',
  date: new Date('2023-10-05'),
  tags: 'Coding, TypeScript, Collaboration',
  excerpt:
    'In the current discussion around TypeScript, we must not forget the crucial role it plays as a common language across distributed teams.',
  content: (
    <>
      <p>
        It's no secret that front-end development is constantly changing. New
        technologies come onto the market while others fade into the background.
        There seems to have been some debate about TypeScript lately, with some
        saying it's on the decline. As Team Lead Frontend Developer, I would
        like to take this opportunity to share my opinion on this debate and
        take a look at the often overlooked aspects.
      </p>
      <p>
        The current discussion focuses primarily on technical challenges such as
        compile time and code complexity, which are leading to an exodus of
        TypeScript from many NPM codebases. These problems are real and
        certainly deserve attention. But in the heat of this debate, some
        essential aspects seem to be overlooked, especially when it comes to
        managing a large codebase with many teams working together in different
        locations with different native languages and levels of experience.
      </p>
      <h2 className="white">The Unsung Heroes: Types and Code Clarity</h2>
      <p>
        One aspect of TypeScript that is often overlooked is the importance of
        its typing. In a large codebase, the value of types becomes particularly
        clear. They not only serve to detect bugs early, but they also act as
        living documentation of the code. This advantage is particularly
        beneficial for teams that span different time zones and whose members
        speak different languages. In a well-typed codebase, the code becomes a
        universal language that can be more easily understood by developers
        around the world.
      </p>
      <h2 className="white">
        The key to collaboration: Typescript as a means of communication
      </h2>
      <p>
        A large codebase is often the playground of different teams with
        different levels of experience. This is where TypeScript comes into play
        by acting as a common interface that facilitates communication between
        developers. Clear types serve as formal contracts between individual
        modules, resulting in better understandability and maintainability.
        Teams can rely on the types to serve as documentation while ensuring
        that changes in one module do not have unexpected effects on other parts
        of the application.
      </p>
      <h2 className="white">
        Uniformity in diversity: Typescript as an integrative tool
      </h2>
      <p>
        In multinational companies where teams operate in different locations,
        code consistency is crucial. TypeScript makes it possible to maintain a
        consistent codebase regardless of whether developers are in Europe,
        North America or Asia. It creates a shared code culture that transcends
        geographical and linguistic boundaries.
      </p>
      <h2 className="white">
        The learning curve as an investment in quality: typification for
        developers on the way up
      </h2>
      <p>
        Another common argument against TypeScript is the steeper learning
        curve, especially for less experienced developers. However, this curve
        should not be viewed as an obstacle but as an investment. Clearly
        defining types allows inexperienced developers to catch errors early and
        promotes a disciplined approach to code. TypeScript type checking serves
        as a teaching tool that helps internalize fundamental concepts of
        software development. This not only improves the code quality, but also
        lays a solid foundation for future development projects. In fact, the
        initial challenge of typing can be seen as a contribution to the
        development of high quality developers.
      </p>
      <h2 className="white">
        Conclusion: Typescript as the key to global frontend development
      </h2>
      <p>
        The discussion about TypeScript should not be based solely on technical
        challenges. Instead, we should recognize the fundamental role TypeScript
        plays in global front-end development. It's more than just an error
        prevention tool; it is a bridge between different teams, locations and
        languages. At a time when collaboration and diversity are becoming
        increasingly important in software development, TypeScript is not on the
        decline, but rather a crucial player in successful and global front-end
        development.
      </p>
      <p className="source">
        <Source handle="joshrh19" name="Joshua Rawson-Harris" />
      </p>
    </>
  ),
};
