import Source from '../../Source';

export const entry = {
  image:
    'https://res.cloudinary.com/iteratus/image/upload/c_fit,f_auto,h_552,w_895/v1648382759/iteratus/denny-muller-7luoHlJdiiU-unsplash_rbj49l.jpg',
  title: 'Launching a new personal website',
  date: new Date('2022-03-26'),
  tags: '#internet, #thoughts, #oldman',
  excerpt:
    "It's not really the need of presenting himself to a broad audience, it's more a personal thing to have one.",
  content: (
    <>
      <p>
        I started to have personal websites as long as I can think of. It
        started around 1996 where I was skipping school, coming to our city's
        main youth club — although by being almost 19 I certainly was already
        too old for it — "wasting" my time exploring everything instead of
        obeying authorities. Not once but countless times I got called by my dad
        and needed to listen to endless monologues about taking responsibilities
        and fulfilling duties.
      </p>
      <p>
        »Jung '96« was it called I remember vividly, the initiative of my
        hometown. There were computers in one corner of the improvised lounge
        area. Don't get me wrong, computer were not that exotic back then. I had
        one at home and of course we had them at school. The really interesting
        part was the internet! It wasn't broadly available anywhere. And schools
        did what they can to restrict the access to it.
      </p>
      <p>
        This was the first time I got in contact with something called
        "Homepage". Putting <strong>&lt;blink&gt;</strong>ing animated GIFs in
        some weird code-like structure, nobody around really knew what it meant,
        code was only passed from one to the other by word of mouth. And all of
        a sudden I had my own MySpace page.
      </p>
      <p aria-hidden={true} style={{ textAlign: 'center' }}>
        —
      </p>
      <p>
        Today is the changeover from winter time to summer time, 26 years later,
        I still create my own personal page. No <strong>&lt;marquee&gt;</strong>{' '}
        elements, no jumping pixel art; the internet changed a long time ago.
        We're in the 3rd web version of it, heading to the 4th. Self-criticism
        and doubts about one's own website remain, albeit in a much weaker form.
        I'm at a point now where I'm not neat picking anymore, I just want to
        have my page out there. "Get the job done" and <strong>have</strong> a
        website. Because The longer one deals with the internet, the more one
        realizes that it's only a tool and that it's all about information. If
        the color does not <strong>exactly</strong> match one's ideal
        expectations... who cares? There always will be people out there
        criticizing every aspect of a certain thing. So let's not fine tuning
        everything to death, let's get the jobs done!
      </p>
      <p>
        And the most important part I learned in the last couple of... decades:
        It's the <strong>internet</strong> we're talking about; it's a living
        document. We can change everything whenever we want. Nobody will
        remember the color from yesterday! Let's rather focus on other things
        and not waste our time with these little things; let's focus on content
        and user experience!
      </p>
      <p className="source">
        <Source handle="redaquamedia" name="Denny Müller" />
      </p>
    </>
  ),
};
