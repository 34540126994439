import React from 'react';

// Important: don't expose real birthday!
const age = Math.floor(
  (new Date() - new Date('1978-01-01').getTime()) / 3.15576e10,
);

const personalInfoContent = [
  { meta: 'name', metaInfo: 'Michael Huber' },
  // { meta: "last name", metaInfo: "Huber" },
  { meta: 'Age', metaInfo: age + ' Years' },
  // { meta: "Nationality", metaInfo: "Austrian" },
  // { meta: "Freelance", metaInfo: "Available" },
  { meta: 'Address', metaInfo: '1220 Vienna, Austria' },
  // { meta: "phone", metaInfo: "+21621184010" },
  {
    meta: 'Email',
    metaInfo: <a href={'mailto:code@iteratus.com'}>code@iteratus.com</a>,
  },
  // { meta: "Skype", metaInfo: " steve.milner" },
  { meta: 'languages', metaInfo: 'German, English' },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
