import React from 'react';
import Index from '../components/about/index';

const About = () => {
  return (
    <div className="about">
      <div>
        <div className="title-section text-left text-sm-center">
          <h1>
            ABOUT <span>ME</span>
          </h1>
          <span className="title-bg">Resume</span>
        </div>
        <Index />
      </div>
    </div>
  );
};

export default About;
