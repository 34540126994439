import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import ModalContent from '../components/portfolio/ModalContent';
import { urlFriendly } from '../utils/utils';

Modal.setAppElement('#root');

const portfolioEntriesRaw = [
  {
    slug: 'xxxlutz-kg',
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1659027673/iteratus/XXXLutz_KG_ctzlnh.png',
    title: 'XXXLutz, Möbelix, Mömax, Poco',
    year: '2016 - now',
    client: 'XXXLutz KG',
    languages: 'ReactJS, GraphQL, Node, Typescript',
    website: 'https://www.xxxlutz.at',
    websiteShort: 'xxxlutz.at',
  },
  {
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648335864/iteratus/Liquid_Nature_glhnod.jpg',
    title: 'Liquid Nature',
    year: '2021 - now',
    client: 'Liquid Nature OG',
    languages: 'Wordpress, Cloudflare',
    website: 'https://www.liquidnature.at',
    websiteShort: 'liquidnature.at',
  },
  {
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648335866/iteratus/Thai-Shiatsu_scparc.jpg',
    title: 'Thai-Shiatsu',
    year: 2022,
    client: 'Katharina Kölbl',
    languages: 'NextJS, Netlify',
    website: 'https://www.thai-shiatsu.at/',
    websiteShort: 'thai-shiatsu.at',
  },
  {
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648335866/iteratus/FragMichi_hqyy51.jpg',
    title: 'FragMichi',
    year: 2022,
    client: 'Dr. Arkadiuz Komorowski',
    languages: 'NextJS, Netlify, PHP',
    website: 'https://www.fragmichi.at/',
    websiteShort: 'fragmichi.at',
  },
  {
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648335865/iteratus/Optimal_Reisen_m1r0ve.jpg',
    title: 'Optimal Reisen',
    year: 2018,
    client: 'Optimal Reisen',
    languages: 'Wordpress',
    website: 'https://www.optimalreisen.at/',
    websiteShort: 'optimalreisen.at',
  },
  {
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648335866/iteratus/Restaurant_Anderwald_c7w04c.jpg',
    title: 'Restaurant Uferwald',
    year: 2018,
    client: 'Camping Anderwald GmbH',
    languages: 'Wordpress',
    website: 'https://faakersee.restaurant/',
    websiteShort: 'faakersee.restaurant',
  },
  {
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648337510/iteratus/Buchbinderei_Waniek_t2cszy.jpg',
    title: 'Buchbinderei Waniek',
    year: 2015,
    client: 'Karner & Schilcher Buchbinderei OG',
    languages: 'HTML, CSS, JS, PHP, MySQL',
    website: 'https://www.buchbinderei-waniek.at/',
    websiteShort: 'buchbinderei-waniek.at',
  },
  {
    slug: 'psychiater-hoebel',
    image:
      'https://res.cloudinary.com/iteratus/image/upload/c_scale,f_auto,w_600/v1648336927/iteratus/Dr._med._univ._Birgit_H%C3%B6bel_g3hhvo.jpg',
    title: 'Psychiater Dr. Birgit Höbel',
    year: 2020,
    client: 'Dr. med. univ. Birgit Höbel',
    languages: 'ReactJS, Netlify',
    website: 'https://www.psychiater-hoebel.at/',
    websiteShort: 'psychiater-hoebel.at',
  },
];

const portfolioEntries = portfolioEntriesRaw.map((entry) => {
  entry.slug = entry.slug ? entry.slug : urlFriendly(entry.title);
  return entry;
});

const Portfolio = () => {
  const navigate = useNavigate();
  const { portfolioId } = useParams();

  const [openData, setOpenData] = useState(null);

  useEffect(() => {
    const data =
      portfolioEntries.find((entry) => entry.slug === portfolioId) || null;
    setOpenData(data);
  }, [portfolioId, setOpenData]);

  const handleClose = () => {
    navigate('../');
  };

  return (
    <div className="portfolio professional">
      <div className="title-section text-left text-sm-center">
        <h1>
          my <span>portfolio</span>
        </h1>
        <span className="title-bg">works</span>
      </div>
      <div className="container grid-gallery main-content">
        <div className="portfolio-tab-content">
          <ul className="row grid justify-content-center">
            {portfolioEntries.map((entry) => (
              <li key={entry.title}>
                <Link to={entry.slug}>
                  <article>
                    <img src={entry.image} alt={entry.title} />
                    <div className=" hover-content-wrapper">
                      <span className="content-title">{entry.title}</span>
                    </div>
                  </article>
                </Link>
              </li>
            ))}
          </ul>

          <Modal
            isOpen={openData !== null}
            onRequestClose={handleClose}
            contentLabel={`Detail to ${openData?.title}`}
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}>
            <>
              <button className="close-modal" onClick={handleClose}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>

              <div className="box_inner portfolio">
                <ModalContent {...(openData || {})} />
              </div>
            </>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
