import Blockquote from '../../Blockquote';
import Source from '../../Source';

export const entry = {
  image:
    'https://res.cloudinary.com/iteratus/image/upload/c_fit,f_auto,h_552,w_895/v1648382759/iteratus/christina-wocintechchat-com-YVT21p6pO_g-unsplash_bfredu.jpg',
  title: 'Thoughts about being a senior developer',
  date: new Date('2022-03-27'),
  tags: '#internet, #thoughts, #oldman',
  excerpt:
    "We don't need to know everything. Nevertheless I often get trapped in exactly this thought! Some thoughts.",
  content: (
    <>
      <p>
        Node, Webpack, Babel, GraphQL, SSR, CSR, XSS, XSRF, JWT, CORS, React,
        Typescript, ICU, RTL, XHR, DOM, CSSOM, Cloud, Kubernetes, YAML, Docker
        ... we all know, this list can go on forever! And one thing we as web
        developers are learning very early in our career is:
      </p>
      <Blockquote>
        <p>
          You're never going to stop learning.
          <br />
          Internet is changing rapidly.
        </p>
      </Blockquote>
      <p>
        Well, that's of course true. But the thing is: This does not mean that
        we need to know all of this. But is it that makes a senior developer?
        Yes, we should be capable to immediately dive into a new topic, because
        everything is repeating itself. It doesn't matter if one isn't fluent in
        one certain aspect of a language or doesn't know immediately the meaning
        of one of these acronyms. But we know how it all is connected and where
        to look at. So the confidence in what we do should be precisely in
        knowing <strong>that we can do it</strong>.
      </p>
      <p>
        Yes, the inevitable imposter syndrome moment will still surprise one
        from time to time; but take a step back, away from the computer, and you
        can see that one can be very proud of what one have achieved in ones
        career!
      </p>
      <p>But why is it so hard sometimes to see it like that!?</p>
      <p className="source">
        <Source handle="wocintechchat" name="Christina @ wocintechchat.com" />
      </p>
    </>
  ),
};
