import React from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'react-simple-snackbar';
import Address from '../components/Address';
import Social from '../components/Social';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      backgroundColor: '#fa5b0f',
    },
  });

  const onSubmit = (data, event) => {
    const formData = new FormData(event.target);

    fetch(event.target.action, {
      method: event.target.method,
      body: formData,
    }).finally(() => {
      openSnackbar(
        <>
          <strong>All done!</strong>
          <br />
          Thanks for sending me an e-mail <i className="fa fa-heart"></i>
        </>,
      );
      event.target.reset();
    });
  };

  return (
    <div className="contact">
      <div className="title-section text-left text-sm-center">
        <h1>
          get in <span>touch</span>
        </h1>
        <span className="title-bg">contact</span>
      </div>
      <div className="container">
        <div className="row">
          {/*  Left Side Starts */}
          <div className="col-12 col-lg-4">
            <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
              Don't be shy !
            </h3>
            <p className="open-sans-font mb-4">
              Feel free to get in touch with me. I am always open to discussing
              new projects, creative ideas or opportunities to be part of your
              visions.
            </p>
            <Address />

            <Social />
          </div>

          <div className="col-12 col-lg-8">
            <form
              className="contactform"
              action="https://api.iteratus.com/"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <input
                      {...register('name', { required: true })}
                      type="text"
                      name="name"
                      placeholder="YOUR NAME"
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className="invalid-feedback">Name is required</span>
                    )}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <input
                      {...register(
                        'email',
                        {
                          required: 'Email is required',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              'Entered value does not match email format',
                          },
                        },
                        { required: true },
                      )}
                      type="email"
                      name="email"
                      placeholder="YOUR EMAIL"
                    />
                    {errors.email && (
                      <span className="invalid-feedback">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <input
                      {...register('subject', { required: true })}
                      type="text"
                      name="subject"
                      placeholder="YOUR SUBJECT"
                    />
                    {errors.subject && (
                      <span className="invalid-feedback">
                        Message is required.
                      </span>
                    )}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      {...register('message', { required: true })}
                      name="message"
                      placeholder="YOUR MESSAGE"></textarea>
                    {errors.message && (
                      <span className="invalid-feedback">
                        Message is required.
                      </span>
                    )}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-12">
                  <button type="submit" className="button">
                    <span className="button-text">Send Message</span>
                    <span className="button-icon fa fa-send"></span>
                  </button>
                </div>
                {/* End .col */}
              </div>
            </form>

            {/* End contact */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
