import React from 'react';

const experience = Math.floor(
  (new Date() - new Date('2001-01-01').getTime()) / 3.15576e10,
);

const achievements = [
  { title: experience, subTitle1: 'Years of', subTitle2: 'experience' },
  { title: '42', subTitle1: 'Happy dev', subTitle2: 'colleagues' },
  { title: '16', subTitle1: 'Managed', subTitle2: 'directs' },
  { title: '2', subTitle1: 'Beautiful', subTitle2: 'kids' },
];

const Achievements = () => {
  return (
    <div className="row">
      {achievements.map((achievement) => (
        <div
          className="col-6"
          key={`${achievement.title}-${achievement.subTitle1}-${achievement.subTitle2}`}>
          <div className="box-stats with-margin">
            <h3 className="poppins-font position-relative">
              {achievement.title}
            </h3>
            <p className="open-sans-font m-0 position-relative text-uppercase">
              {achievement.subTitle1}{' '}
              <span className="d-block">{achievement.subTitle2}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
