import React from 'react';

const Source = ({ handle, name }) => {
  return (
    <>
      Photo by{' '}
      <a
        href={`https://unsplash.com/@${handle}?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText`}
        target="_blank"
        rel="noreferrer noopener">
        {name}
      </a>{' '}
      on{' '}
      <a
        href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noreferrer noopener">
        Unsplash
      </a>
    </>
  );
};

export default Source;
