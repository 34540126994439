import React from 'react';
import { Link } from 'react-router-dom';

const heroContent = {
  heroImage: 'img/hero/dark.jpg',
  heroMobileImage: 'img-mobile',
  heroTitleName: '404',
  heroDesignation: 'Page not found',
  heroDescriptions: `Even as a developer, it can happen that the content has either shifted or that the content
  you was looking for is simply no longer available. Please use the button below to explore the page.`,
  heroBtn: 'Navigate to start page',
};

const NotFound = () => {
  return (
    <div className="home">
      <div className="container-fluid main-container container-home p-0">
        <div className="color-block d-none d-lg-block"></div>
        <div className="row home-details-container align-items-center">
          <img
            className="col-lg-4 bg position-fixed d-none d-lg-block"
            src={
              'https://res.cloudinary.com/iteratus/image/upload/f_auto/v1648229453/iteratus/iteratus_lidhsd'
            }
            style={{ objectFit: 'cover', padding: 0 }}
            alt={heroContent.heroTitleName}
          />
          <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
            <div>
              <img
                src={
                  'https://res.cloudinary.com/iteratus/image/upload/f_auto/v1648229869/iteratus/iteratus-square_wtmxtx'
                }
                className="img-fluid main-img-mobile d-sm-block d-lg-none"
                alt={heroContent.heroTitleName}
              />
              <h1 className="text-uppercase poppins-font">
                {heroContent.heroTitleName}
                <span>{heroContent.heroDesignation}</span>
              </h1>
              <p className="open-sans-font">{heroContent.heroDescriptions}</p>
              <Link className="button toggleModalOne" to="/">
                <span className="button-text">{heroContent.heroBtn}</span>
                <span className="button-icon fa fa-arrow-right"></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
