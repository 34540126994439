import React from 'react';

const ModalContent = ({ title, image, date, tags, content }) => {
  return (
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>

      <div className="meta open-sans-font">
        <span className="date">
          <i className="fa fa-calendar"></i>{' '}
          {date?.toLocaleDateString('en-GB', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </span>
        <span>
          <i className="fa fa-tags"></i> {tags}
        </span>
      </div>

      <h1>{title}</h1>
      <img src={image} className="img-fluid" alt={title} />
      <div className="blog-excerpt open-sans-font pb-5">{content}</div>
    </article>
  );
};

export default ModalContent;
