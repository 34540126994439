import React from 'react';

const experienceContent = [
  {
    icon: 'fa-briefcase',
    year: '2022 - now',
    position: 'Chapter Lead + Head of Frontend Guild',
    companyName: 'XXXLdigital',
    details: `The desire to get back to coding and working with people again was strong enough to decide to get back
    to development. While still influencing the frontend architecture through the role of head of frontend guild, I can
    finally help colleagues grow and lay my hands on code again.`,
  },
  {
    icon: 'fa-briefcase',
    year: '2021 - 2022',
    position: 'Lead Frontend Architect',
    companyName: 'XXXLdigital',
    details: `The role of Lead Frontend Architect arose from the need for working communication between non-technical
    stakeholders and the technical requirements of our e-commerce stack.`,
  },
  {
    icon: 'fa-briefcase',
    year: '2019 - 2021',
    position: 'Head of Frontend Development',
    companyName: 'XXXLdigital',
    details: `My many years of experience paired with the increasing demands of a constantly growing team enabled me to
    take on the role of Head of Frontend Development.`,
  },
  {
    icon: 'fa-briefcase',
    year: '2016 - 2019',
    position: 'Senior Web Frontend Developer',
    companyName: 'XXXLdigital',
    details: `The desire for new perspectives then brought me to XXXLutz. Here I was able to fully expand my team
      strengths and quickly took on the role of the Viennese team lead. My responsibility was expanded by taking on the
      position of frontend architect, for which I was responsible for two years. The real passion, however, lies in the
      further development of my colleagues, which is why I am currently focussing on my dev roots and have taken on the
      role of a Chapter Lead of the Viennese team.`,
  },
  {
    icon: 'fa-briefcase',
    year: '2005 - 2016',
    position: 'Fullstack Web Developer',
    companyName: 'iService Ottner Röck OG',
    details: `In eleven years I developed further as a full stack developer. I built several online platforms such as
      Kulturvernetzung Niederösterreich, Filminstitut Österreich, Amnesty International Austria and WWF Austria.
      Behind it was a self-developed content management system that could also be used for community management.`,
  },
  {
    icon: 'fa-briefcase',
    year: '2004 - 2005',
    position: 'Freelance Web Developer',
    companyName: 'iteratus - Michael Huber',
    details: `In this short time I got a taste of self-employment and had to realize that acquisition is not my forte.
      But you learn an incredible amount from failure, and I wouldn't want to miss this experience. The greatest
      achievement during this time was the creation of a multi-language online shop with international shipping.`,
  },
  {
    icon: 'fa-graduation-cap',
    year: '2002 - 2004',
    position: 'Junior Web Developer',
    companyName: '[WORX] Multimedia Consulting GmbH',
    details: `Parallel to my education at the SAE, I was already working in the agency as a Junior Web Dev. My main
      focus was on the creation of landing pages and Flash advertising for well-known companies such as UNIQA,
      Constantin Film, Columbia Tristar and many more.`,
  },
  {
    icon: 'fa-graduation-cap',
    year: '2001 - 2002',
    position: 'Diploma certificate',
    companyName: 'SAE - Multimedia Producer',
    details: `Although I was already an autodidact full stack developer at the time, I use the time at SAE to further
      develop my coding skills and ultimately graduate.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className={`fa ${val.icon}`}></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.companyName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
